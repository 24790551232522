import React, {useState, useEffect, useRef} from "react"
import { Helmet } from "react-helmet";
import {navigate} from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ThankyouBlock from "../../components/ThankyouBlock/ThankyouBlock";
import {addCommas, removeNonNumeric} from "../../components/common/utils";
import PropertyToolkit from "../../components/Valuation/PropertyToolkit/PropertyToolkit"

import {useStaticQuery, graphql } from 'gatsby'
import { removeDataAttributes } from "../../comQueryStructure";
// styles

// markup
const PreQualifiedConfirmation = ( props ) => {

  const qualified_data = removeDataAttributes(props?.data)

  let path_name = (props.location.pathname).split('/');
  let get_url = path_name[path_name.length-1];

  var find_prop_url = '';

  if(get_url === "secondary-market" || get_url === "resale"){
    find_prop_url = "/properties/for-sale/in-doha-qatar"
  } else if(get_url === "primary-market" || get_url === "payment-plans"){
    find_prop_url = "/properties/for-sale/in-doha-qatar"
  } else if(get_url === "commercial-sale"){
    find_prop_url = "/properties/commercial/for-sale/in-doha-qatar/"
  } else if(get_url === "commercial-lease"){
    find_prop_url = "/properties/commercial/for-rent/in-doha-qatar/"
  } else if(get_url === "offplan-projects"){
    find_prop_url = "/properties/for-sale/in-doha-qatar"
  } else if(get_url === "lease-to-own"){
    find_prop_url = "/properties/for-rent/in-doha-qatar/"
  } else if(get_url === "rental-listing"){
    find_prop_url = "/properties/for-rent/in-doha-qatar/"
  } else if(get_url === "land-plot"){
    find_prop_url = "/properties/for-sale/in-doha-qatar"
  } else if(get_url === "land-owners"){
    find_prop_url = "/properties/for-sale/in-doha-qatar"
  } else{
    find_prop_url = ""  
  }

  var GQLPage = qualified_data?.glstrapi?.article;
  var GQLModules = qualified_data?.glstrapi?.article?.Add_Modules;

  return (
    <Layout Layout={'Without_Banner'} classNames="Without_Banner">

      <SEO title={'Prequalified Form'} description={'Prequalified Form'} noindex={true} />
      
      <Helmet/>

      <div className="page-content pre_thankyou_page">
        {GQLModules.map((Modules, i) => {
          return(<>
            {
              Modules.__typename === "GLSTRAPI_ComponentModValuationThankyou" &&
              <ThankyouBlock Pagename={GQLPage.Pagename} Modules={Modules} Alias={GQLPage.Alias} find_prop_url={find_prop_url} />
            }
  
            {
              Modules.__typename === "GLSTRAPI_ComponentComToolkit" &&
              <PropertyToolkit Toolkit={Modules}/>
            }
            </>
          )}
        )}          
      </div>

    </Layout>
  )
}
export const query = graphql`
  query PreQualQuery{

    glstrapi {

      article(id: "10") {
        data {
          id
          attributes {
            Alias
            Content_Heading_Text
            Content
            Layout
            Meta_Title
            Meta_Description
            Page_CSS_Class
            Custom_CSS_Class
            Pagename
            Publish
            imagetransforms
            ggfx_results {
              data {
                attributes {
                  src_cftle
                  transforms 
                }
              }
            }
            Header_Banner_Image {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
            Header_Banner_Video
            popular_search {
              data {
                attributes {
                  Module_Name
                  ModuleType {
                    ... on GLSTRAPI_ComponentModContentBlock {
                      id
                      Title
                      Content
                    }
                  }
                }
              }
            }
            Add_Modules {
              __typename
              
              ... on GLSTRAPI_ComponentModValuationThankyou {
                id
                Title
                Content
                CTA_1_URL
                CTA_1_Label
              }
    
              ... on GLSTRAPI_ComponentComToolkit {
                id
                Title
                Snippet_Box {
                  CTA_1_Label
                  CTA_1_URL
                  Content
                  Icon {
                    data {
                      attributes{
                        url
                        alternativeText
                      }
                    }
                  }
                  Title
                  id
                }
              }
            }
          }
        }
      }
    }
}`

export default PreQualifiedConfirmation